.committed{
    /* display: flex; */
    height: auto;
    width: 100vw;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.committed-h1 h1{
    font-family: 'Times New Roman';
    font-size: 180%;
    margin-top: 30px;
    padding: 15px;
}

.cr-para{
    
}

.containers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: fliter 0.3s ease-in-out;
    border-radius: 10px;
}

.box {
    height: auto;
    min-height: 150px;
    width: 450px;
    margin: 25px;
    padding: 10px;
    border: 1px solid black;
    /* border-bottom: 1px solid black; */
    font-family: 'Times New Roman';
    color: rgb(104, 74, 29);
    border-radius: 10px;
}

.box h3{
    color: black;
    font-size: large;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(190, 186, 186);
}

.box p{
    padding: 5px;
}

.box:hover{
    cursor: pointer;
}

/* @media  screen and (max-width: 600px) { */
    /* .committed{
        width: 100;
    }
    .committed h1{
        font-size: 30px;
    }
    .containers{
        display: flex;
        flex-direction: column;
        /* position: absolute; */
        /* padding: 10px;
        margin: auto;
    }

    .box{
        width: 300px;
        margin: auto;
        justify-content: center;
        margin: 10px;
    }

    .box h3{
        font-size: medium;
    }

    .box p{
        font-size: small;
    } */ 


    /* .committed {
        width: 100%;
        padding: 15px;
    }

    .committed-h1 h1 {
        font-size: 20px; /* Further reduced font size for mobile 
    }

    .containers {
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin: 0;
    }

    .box {
        width: 90%; /* Increased width for smaller screens 
        margin: 10px auto;
        justify-content: center;
    }

    .box h3 {
        font-size: 16px; /* Adjusted font size for mobile 
    }

    .box p {
        font-size: 12px; /* Adjusted font size for mobile 
    }
} */

@media screen and (max-width: 768px) {
    .containers {
        display: flex;
        overflow-x: auto; /* Enable horizontal scroll */
        scroll-behavior: smooth;
        scrollbar-width: none; /* Hide scrollbar */
        -ms-overflow-style: none;
        cursor: grab;
        user-select: none;
    }

    .containers::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari */
    }

    .box {
        min-width: 80%; /* Adjust width for better scrolling */
        margin: 10px;
    }

    .box p{
        font-size: 10px;
    }
}