/*

.BestSellerContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
}

.BestSellerContainer {
  font-size: 40px;
  font-family: 'Times New Roman';
}

.bestSellerBox {
  display: flex;
  flex-wrap: wrap;
  margin: 40px;
}

.subBox {
  width: 22%;
  margin: 20px;
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;
}

.buybox {
  font-size: medium;
  text-shadow: 1px;
  text-align: center;
  width: 90%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  font-family: 'Times New Roman', Times, serif;
  transition: opacity(0.3s);
  text-decoration: none;
  list-style-type: none;
}

.subBox button {
  font-size: medium;
  text-shadow: 1px;
  text-align: center;
  width: 90%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  font-family: 'Times New Roman', Times, serif;
  transition: opacity(0.3s);
}

.subBox button :hover {
  font-size: 20px;
  transform: scale(1.1);
  background-color: rgb(126, 127, 129);
}

@media screen and (max-width: 600px) {

  .BestSellerContainer {
    font-size: 20px;
    /* Further reduced font size for better mobile readability 
    padding: 10px;
    /* Adjusted padding 
  }

  .bestSellerBox {
    display: flex;
    flex-direction: row;
    /* Stack the boxes vertically on smaller screens 
    justify-content: center;
    margin: 0;
  }

  .subBox {
    width: 40%;
    /* Adjusted width to take up more space on smaller screens 
    margin: 15px auto;
    /* Center aligned with margin */
    /* Added padding for better spacing 
  }



  .subBox button {
    font-size: 10px;
    /* Adjusted font size for mobile 
    margin: auto;
  }

  .subBox button:hover {
    font-size: 10px;
    /* Adjusted hover font size 
  }
}

*/


/* Container for the Best Seller section */
.BestSellerContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Heading styles */
.BestSellerHeading {
  font-size: 40px;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  margin-bottom: 20px;
}

/* The slider container */
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

/* The scrolling track */
.sliderTrack {
  display: flex;
  width: 200%; /* Allows seamless looping */
  animation: scroll 15s linear infinite;
}

/* Scroll animation */
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

/* Individual product container */
.sliderItem {
  flex: 0 0 auto;
  width: 300px;
  margin: 10px;
  text-align: center;
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Product images */
.sliderItem img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Button container to stack buttons below each other */
.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px; /* Spacing between buttons */
  margin-top: 10px;
}

/* Product buttons */
.sliderItem button {
  font-size: 16px;
  width: 90%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  padding: 3px;
  font-family: "Times New Roman", Times, serif;
  background-color: white;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Hover effect on buttons */
.sliderItem button:hover {
  transform: scale(1.1);
  background-color: rgb(126, 127, 129);
  color: white;
}

/* Buy Now button styles */
.buybox {
  font-size: 16px;
  text-align: center;
  width: 90%;
  border: 1px solid black;
  border-radius: 10px;
  margin: auto;
  padding: 3px;
  font-family: "Times New Roman", Times, serif;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

/* Buy Now hover effect */
.buybox a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.buybox:hover {
  background-color: rgb(126, 127, 129);
}

/* Responsive Design */
@media screen and (max-width: 600px) {
  .BestSellerHeading {
    font-size: 24px;
  }

  .sliderItem {
    width: 150px;
  }

  .sliderItem button {
    font-size: 14px;
  }

  .buybox {
    font-size: 14px;
  }
}


@media screen and (max-width: 600px) {
  .BestSellerHeading {
    font-size: 24px; /* Reduced font size */
    margin-bottom: 15px;
  }

  /* Adjust individual product container width for mobile */
  .sliderItem {
    width: 150px;
    margin: 10px 5px;
  }

  .sliderItem img {
    max-height: 180px; /* Adjust image size for smaller screens */
  }

  .sliderItem button {
    font-size: 10px; /* Adjust font size for mobile */
  }

  .buybox {
    font-size: 10px; /* Adjust font size for mobile */
  }

  .slider {
    padding: 10px;
  }
}