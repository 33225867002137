.nav {
    margin: 0;
    padding: 0;
    width: 100vw;
    position: static;
    position: fixed;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    height: auto;
    background-color: rgba(255, 255, 255);
    top: 0;
    transition: height 0.3s ease;
}

.navbar.shrink {
    display: flex;
}

.store {
    display: flex;
    align-items: center;
}

.store:hover {
    scale: (1.1);
    text-decoration: underline;
}

.line {
    width: 100vw;
    border-bottom: 1px solid rgb(162, 162, 162);
}

.nav-left {
    display: flex;
    align-items: center;
    float: left;
}

.toplogo {
    display: none;
    float: left;
    max-width: 100px;
    max-height: fit-content;
    display: block;
    margin-right: 15px;
}

.nav-righ {
    display: flex;
    align-items: center;
    float: right;
}

.navbar {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100vw;
    background-color: rgba(255, 255, 255);
    top: 0;
    left: 0;
}

.nav-items {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: auto;
    font-family: 'Times New Roman';
}

.nav-list {
    display: inline-block;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
}

.nav-list a {
    text-decoration: none;
    transition: background-color 0.3 ease;
    padding: 15px;
}

.nav-list:hover {
    color: rgb(58, 41, 21);
    text-decoration: underline;
    border-radius: 30px;
}

.categories{
    display: flex;
    float: left;
    text-decoration: none;
    font-size: bolder;
    list-style-type: none;
    margin: auto;
    padding: 10px;
    align-items: center;
}

.categories a {
    margin-left: 9px;
}

.categories:hover {
    background-color: rgb(229, 224, 213);
    border-radius: 30px;
}

.brand {
    margin: auto;
    margin-right: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-size: bolder;
}


.drop-down {
    position: relative;
    background: transparent;
    box-shadow: none;
    z-index: 1000;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.dropdown-content a {
    font-family: 'Times New Roman';
    text-decoration: none;
}

.hover-element:hover+.dropdown-content {
    display: block;
}

.hover-element {
    cursor: pointer;
}

.menu-icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    size: 30px;
    display: none;
}

.menutoggle {
    size: 140%;
}

.mini-nav {
    display: none;
    width: 50px;
    height: 50px;
    justify-content: center;
    margin: auto;
    align-items: center;
}

.mobile-menu-icon{
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: black;
}

.line.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.line.open:nth-child(2) {
    opacity: 0;
}

.line.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.mobile-nav {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100vw;
    background-color: #333;
    z-index: 1000;
}

.mobile-nav.open {
    display: flex;
}

.mobile-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100;
    text-align: center;
}

.mobile-nav ul li {
    padding: 15px 0;
}

.mobile-nav ul li a {
    color: #fff;
    text-decoration: none;
    display: block;
    width: 100%;
}

.mobile-logo{
    display: none;
}

@media only screen and (max-width: 768px) {
    @media (max-width: 768px) {
        .nav{
            width: 100%;
        }
        
        .nav-items {
            display: none;
        }
    
        .top-logo {
            margin-left: auto;
            margin-right: auto;
        }
    
        .mobile-menu-icon {
            margin-left: 20px;
            display: flex;
            color: #333;
        }

        .mobile-logo{
            /* flex: 1; */
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
        }
    }
}