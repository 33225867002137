.aboutus{
    width: 100vw;
    height: auto;
    margin: 10px 0px 10px 0px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.abouthead {
    margin-top: 30px;
    padding: 15px;
    font-family: 'Times New Roman';
    color: rgb(0, 0, 0);
    font-size: 180%;
}

.aboutContaint{
    display: flex;
    width: 100%;
}

.aboutImage {
    width: 50%;
}

.aboutText {
    width: 60%;
    padding: 20px;
    /* text-align: left; */
    line-height: 1.5;
    font-family: 'Times New Roman';
    justify-content: center;
    font-size: 1.4em;
}


.objective{
    width: 100vw;
    /* height: 600px; */
    margin: auto;
}

.ourObjective img{
    width: 100%;
    margin: auto;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
}

@media  screen  and (max-width: 600px){
    
    .aboutContaint{
        display: flex;
        width: 100vw;
        flex-direction: column;
    }

    .aboutImage{
        width: 100vw;
    }

    .aboutText{
        width: 100vw;
        /* text-align: justify; */
        font-size: 12px;
    }
}