.video {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  height: auto;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.5); 
}

.videos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-box {
  border-radius: 10px;
  max-height: 650px;

}

.video-head {
  font-family: 'Times New Roman';
  display: inline;
  top: -7px;
  left: 550px;
  margin: auto;
  align-items: center;
  font-size: 150%;
  position: absolute;
  color: rgb(255, 255, 255);
  z-index: 999;
  /* background-color: rgba(255, 255, 255, 0.5);  */
  border-radius: 10px;
  text-align: center;
}

.video-player-container {
  max-width: 600px;
  margin: 0 auto;
}

.video-player {
  width: 100%;
}

.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.volume-slider {
  width: 100px;
}

.video-style {
  display: flex;
  float: left;
  margin: auto;
  /* padding-right: 5px; */
  padding: 90px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.video-gap{
  padding: 25px;
}

.N-video{
  width: 15%;
}

@media screen and (max-width: 600px) {
  .video-head {
    display: none;
    font-size: 120%;
    top: 5px;
    left: 0;
    text-align: center;
    width: 100%;
    padding: 5px;
    box-shadow: none;

  }

  .videos {
    display: none;
    justify-content: space-between; /* Ensure side-by-side display on mobile */
    flex-wrap: wrap;
  }

  .video-box {
    width: 90%; /* Keep videos side by side */
    max-height: 300px;
  }

  .video-player-container {
    max-width: 90%;
  }

  .video-player {
    width: 100%;
    height: 200px;
  }

  .controls-container {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .button {
    padding: 6px 12px;
    font-size: 14px;
  }

  .video-style {
    flex-direction: row;
    padding: 5px;
  }

  .video-gap {
    padding: 10px;
  }

  .N-video {
    width: 90%; /* Adjust size for mobile */
  }
}