.contactbox {
    height: auto;
    width: 100%;
    background-color: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
}

.contactbox p {
    text-align: center;
}

.contactus h1 {
    font-family: 'Times New Roman';
    font-size: 30px;
}

.contactbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: fliter 0.3s ease-in-out;
}

.contactus {
    width: 100vw;
    height: auto;
    margin: 25px;
    padding: 10px;
    font-family: 'Times New Roman';
    color: rgb(0, 0, 0);
    border-radius: 10px;
    font-size: 12px;
}

.contact-coloum {
    display: flex;
    flex-direction: column;
    float: left;
    margin-left: 60px;
    padding: 20px;
}

.contactus h3 {
    font-size: bold;
}

.contactus h2 {
    color: black;
}

.contactus:hover {
    cursor: pointer;
}

.contactus h2 {
    border: bottom 1px solid black;
    margin: 5px;
}

.contactus p {
    text-align: left;
    width: 100%;
    margin-left: 240px;
}

.follow-us {
    margin-top: 10px;
    margin-right: 10px;
    width: 100%;
    font-size: 12px;
}

.follow-us h2 {
    border: bottom 1px solid black;
    margin: 5px;
    font-family: 'Times New Roman', Times, serif;
}

.line {
    border-bottom: 0.5px solid rgb(191, 191, 191);
    width: 100%;
    padding: 2px;
}

.icon {
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding: 26px;
    margin-right: 10px;
    color: black;
}

.icon:hover {
    color: rgb(104, 74, 29);
    cursor: pointer;
}


.QNA h2 {
    border: bottom 1px solid rgb(131, 131, 131);
    margin: 5px;
    font-family: 'Times New Roman', Times, serif;
    font-size: bold;
}

.indexlogo {
    width: 100%;
    height: auto;
    display: flex;
    float: left;
    background-color: none;
}

.brandlogo {
    height: auto;
    padding: 5px;
    justify-content: center;
    margin: auto;
}

.brandlogo img {
    width: 100px;
    justify-content: center;
}

.brand {
    font-family: 'Times New Roman';
    font-size: 120%;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
}

.follo-logo {
    width: 100%;
    display: flex;
}

.left-contant {
    text-align: left;
    display: flex;
    float: left;
    padding: 10px;
    /* border-right: 1px solid rgb(188, 188, 188); */
}

.right-contant {
    text-align: left;
    display: flex;
    float: right;
    padding: 10px;
}

.follo-logo{
    background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 600px) {

    .contactus{
        /* width: 100%;
        margin-left: 70px; */
    }

    .follow-text{
        display: inline-block;
        align-items: center;
        justify-content: center;
        padding: 26px 0px;
        margin-right: 10px;
        color: black;
    }

    .line{
        display: none;
    }

    .follo-logo{
        display: flex;
        flex-direction: column;
    }

    .follow-us {
        display: flex;
        flex-direction: row;
    }

    .brandlogo{
        display: flex;
        flex-direction: row;
    }

    .brand{
        width: 75%;
    }

    .brand h2{
        width: 100%;
        font-size: 16px;
        /* background-color: bisque; */
    }

    .logo{
        width: 25%;
    }

    .follow-us .icon {
        margin: auto;
    }

    .left-contant {
        text-align: left;
        display: flex;
        float: left;
    }

    .right-contant {
        text-align: left;
        display: flex;
        float: right;
    }


}