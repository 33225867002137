.benefites {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    overflow: hidden; /* Hide overflow to create a clean scrolling effect */
}

.benefitesbox {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    animation: continuousScroll 20s linear infinite; /* Continuous scroll animation */
    will-change: transform; /* Optimization for smoother scrolling */
}

@keyframes continuousScroll {
    0% {
        transform: translateX(0); /* Start from the initial position */
    }
    100% {
        transform: translateX(-100%); /* Move left by 100% of the container's width */
    }
}

.innerbox {
    width: 250px;
    height: 380px;
    background-color: rgb(250, 156, 156);
    border-radius: 10px;
    margin: 15px; /* Spacing between boxes */
    padding: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    flex-shrink: 0; /* Prevent shrinking of the boxes */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.innerbox h2 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
    margin-bottom: 20px;
}

.benefites h1 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 20px;
}

/* Individual box color themes */
.box1 {
    background-color: #f7f7f7;
}

.box2 {
    background-color: #f0e6d2;
}

.box3 {
    background-color: #e0f7fa;
}

.box4 {
    background-color: #d0f0c0;
}

.box5 {
    background-color: #d1e2f1;
}

/* Hover effect for the inner box */
.innerbox:hover {
    filter: brightness(0.9);
    transform: scale(1.1);
}

/* Mobile responsiveness */
@media screen and (max-width: 600px) {
    .benefitesbox {
        animation: none !important; /* Disable automatic scrolling */
        overflow-x: auto; /* Allow manual scrolling */
        scroll-snap-type: x mandatory; /* Smooth scrolling effect */
        /* display: flex; */
        /* flex-wrap: nowrap; */
        /* white-space: nowrap; */
    }
    .innerbox {
        width: 80%; /* Adjusted for smaller screens */
        /* margin:  auto; */
        height: 100px;
        flex: 0 0 90%; /* Adjust width for better scrolling */
        scroll-snap-align: center;
    }

    .innerbox h2{
        font-size: 10px;
    }

    .benefites h1 {
        font-size: 30px; /* Adjusted font size for smaller screens */
    }

    .innerbox img{
        width: 100%;
    }
}
